import { PAGE_WP_TYPE } from '../contentTypes/page';
import { returnTemplate } from '../lib/_client';
import { returnStaticProps } from '../lib/_helpers';
import { getSnowflakeData } from '../lib/getSnowflakeData';
import Support from '../templates/Support';

export default function Template(props) {
  return returnTemplate(props, Support);
}

const termsToStrings = (blocks) => {
  const result = {};

  for (const block of blocks) {
    result[block.attributes.data['data-key']] =
      block.innerBlocks[0].attributes.innerContent;
  }

  return result;
};

export async function getStaticProps(oProps) {
  const page = await returnStaticProps({
    ...oProps,
    slug: 'support-status',
    type: PAGE_WP_TYPE,
  });

  if (page.props?.construct) {
    const strings = termsToStrings(page.props.construct.blocks);
    var { props } = await returnStaticProps({
      ...oProps,
      query: getSnowflakeData,
      slug: 'support-status',
      type: PAGE_WP_TYPE,
    });

    props.globals = page.props.globals || null;

    props.construct.main.Chat.increment = strings.minutesKey;
    props.construct.main.Call.increment = strings.percentageKey;
    props.construct.main.Email.increment = strings.hoursKey;
    props.construct.main.Touches.increment = strings.percentageKey;
    props.construct.main.CSAT.increment = strings.percentageKey;

    props.construct.main.Chat.description = strings.liveChatTooltipKey;
    props.construct.main.Call.description = strings.percentsLiveChatTooltipKey;
    props.construct.main.Email.description = strings.emailTooltipKey;
    props.construct.main.Touches.description = strings.percentFCRTooltipKey;
    props.construct.main.CSAT.description = strings.percentsCSATTooltipKey;

    props.construct.strings = strings;
  }

  return props ? { props } : page;
}
