import React, { useState } from 'react';

import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import Icon from '../components/basic/Icon';
import Paragraph from '../components/basic/Paragraph';

const getText = (type) => {
  if (type === 'Call') {
    return 'Chat-to-Call Conversion Rate';
  }

  if (type === 'CSAT') {
    return 'Customer Satisfaction Score';
  }

  return 'TFR';
};

const CustomTooltip = ({
  active, isToggled, payload, strings, type,
}) => {
  let key = 'MedianFormatted';

  if (['Call', 'CSAT'].includes(type)) {
    key = 'val';
  } else if (isToggled) {
    key = 'p90Formatted';
  }

  if (active && payload && payload.length > 0) {
    const rightHalf =
      type === 'Touches' ? (
        <div>
          <p className="text-12 leading-16">{strings.responsesKey}</p>
          <p className="text-12 leading-16" style={{ fontWeight: 500 }}>
            1: {payload[0].payload['1']}%
          </p>
          <p className="text-12 leading-16" style={{ fontWeight: 500 }}>
            2: {payload[1].payload['2']}%
          </p>
          <p className="text-12 leading-16" style={{ fontWeight: 500 }}>
            3: {payload[2].payload['3']}%
          </p>
          <p className="text-12 leading-16" style={{ fontWeight: 500 }}>
            4+: {payload[3].payload['4 or more']}%
          </p>
        </div>
      ) : (
        <div>
          <p className="text-12 leading-16">{getText(type)}</p>
          <p className="text-12 leading-16" style={{ fontWeight: 500 }}>
            {payload[0].payload[key]}
            {['Call', 'CSAT'].includes(type) ? '%' : ''}
          </p>
        </div>
      );

    return (
      <div
        className="flex p-12 bg-white"
        style={{
          border: '1px solid #E0DEDE',
          boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.06)',
        }}
      >
        <div className="pr-12 mr-12 border-r-1 border-black border-opacity-20">
          <p className="text-12 leading-16">{strings.dayKey}</p>
          <p className="text-12 leading-16" style={{ fontWeight: 500 }}>
            {payload[0].payload.date}
          </p>
        </div>
        {rightHalf}
      </div>
    );
  }

  return null;
};

let firstClassName = null;

const CustomizedXAxisTick = (props) => {
  const {
    height, isPercent, payload, width, x, y,
  } = props;
  var className;

  if (payload.value && !firstClassName) {
    firstClassName = payload.value;
  }
  if (payload.value === firstClassName) {
    className = '-first';
  }

  return (
    <text
      className={className || undefined}
      height={height}
      textAnchor="middle"
      width={width}
      x={x}
      y={y}
    >
      <tspan dy={22} style={{ color: '#595555' }}>
        {payload.value}
        {payload.value && isPercent ? '%' : ''}
      </tspan>
    </text>
  );
};

const CustomizedYAxisTick = (props) => {
  const {
    className, height, isPercent, payload, width, x, y,
  } = props;

  return (
    <text
      className={className}
      height={height}
      textAnchor="end"
      width={width}
      x={x}
      y={y}
    >
      <tspan dy=".4rem" style={{ color: '#595555' }}>
        {payload.value}
        {payload.value && isPercent ? '%' : ''}
      </tspan>
    </text>
  );
};

/* const CustomLabel = (props) => {
  const labelStyles = {
    color: '#808080',
    'font-size': 15,
    fontWeight: 500,
    'letter-spacing': 2,
    position: 'absolute',
    'text-transform': 'uppercase',
  };

  return (
    <foreignObject
      className="label-wrapper recharts-label-transform"
      style={{
        height: '100%',
        width: 30
      }}
      x={props.viewBox.x}
      y="0"
    >
      <div
        className="custom-label"
        style={labelStyles}
        xmlns="http://www.w3.org/1999/xhtml"
      >
        {props.increment}
      </div>
    </foreignObject>
  );
}; */

const Graph = ({
  data, strings, type,
}) => {
  const [isToggled, setIsToggled] = useState(false);

  const headings = {
    Call: strings.percentsLiveChatKey,
    Chat: strings.liveChatResponseTimeKey,
    CSAT: strings.percentsCSATKey,
    Email: strings.emailKey,
    Touches: strings.percentResolvedFirstResponseKey,
  };

  const iconClass = {
    Call: 'w-24 h-24',
    Chat: 'w-18 h-16',
    CSAT: 'w-24 h-24',
    Email: 'w-20 h-14',
    Touches: 'w-24 h-18',
  };

  const wrapperStyles = {
    border: '1px solid #E0DEDE',
    borderRadius: '2px',
  };

  const togglerStyles = {
    height: 'calc(100% + 2px)',
    left: '-1px',
    top: '-1px',
    width: 'calc(50% + 1px)',
  };

  let dataKey = 'Median';
  if (['Call', 'CSAT'].includes(type)) {
    dataKey = 'val';
  } else if (isToggled) {
    dataKey = 'p90';
  }

  const lineColor = isToggled ? '#DF6F25' : '#502E3C';
  const theAverage =
    data.average90p && isToggled ? data.average90p : data.average;

  const questionMarkIcon = (respClass) => (
    <div
      className={`wrapper compare-tooltip cursor-pointer group items-center ${respClass}`}
    >
      <div className="w-20 h-20 flex items-center justify-center">
        <Icon
          attributes={{
            className: 'w-20 h-20',
            color: 'grey-4',
            name: 'QuestionMark',
            style: 'filled',
            type: 'functional',
          }}
        />
      </div>
      <div className="wrapper compare-tooltip-box compare-tooltip-box--align-right bg-white border-1 border-tertiary-gray3 pb-16 pl-16 pr-16 pt-16 rounded-2">
        <p className="is-style-p2 text-black">{data.description}</p>
      </div>
    </div>
  );

  let icon = type;
  switch (type) {
    case 'Touches': {
      icon = 'Tasks';

      break;
    }
    case 'CCR': {
      icon = 'CheckCircle';

      break;
    }
    case 'CSAT': {
      icon = 'CSAT';

      break;
    }

  // No default
  }

  let translatePx = '-30px';
  if (type === 'Touches') {
    translatePx = '-50px';
  } else if (['Call', 'CSAT'].includes(type)) {
    translatePx = '-40px';
  }

  const lines =
    type === 'Touches' ?
      [
        <Line
          key="1"
          activeDot={{
            fill: '#FDB71C',
            r: 8,
          }}
          dataKey="1"
          dot={false}
          stroke="#FDB71C"
          strokeWidth={2}
          type="linear"
        />,
        <Line
          key="2"
          activeDot={{
            fill: '#196E5F',
            r: 8,
          }}
          dataKey="2"
          dot={false}
          stroke="#196E5F"
          strokeWidth={2}
          type="linear"
        />,
        <Line
          key="3"
          activeDot={{
            fill: '#502E3C',
            r: 8,
          }}
          dataKey="3"
          dot={false}
          stroke="#502E3C"
          strokeWidth={2}
          type="linear"
        />,
        <Line
          key="4"
          activeDot={{
            fill: '#C3402C',
            r: 8,
          }}
          dataKey="4 or more"
          dot={false}
          stroke="#C3402C"
          strokeWidth={2}
          type="linear"
        />,
      ]
      : (
        <Line
          key="5"
          activeDot={{
            fill: lineColor,
            r: 8,
          }}
          dataKey={dataKey}
          dot={false}
          stroke={lineColor}
          strokeWidth={2}
          type="linear"
        />
      );

  return (
    <div className="grid xsx:max-w-none smx:max-w-none mb-32">
      <div className="row flex-col p-20 sm:p-32 bg-white rounded-2">
        <div className="sm:flex items-center justify-between sm:border-b-1 border-black border-opacity-20 pb-12 mb-12 sm:pb-22 sm:mb-14">
          <div
            className={`sm:flex items-center mb-16 sm:mb-0 ${
              [
                'Call',
                'CSAT',
                'Touches',
              ].includes(type)
                ? 'sm:w-70%'
                : 'sm:w-42%'
            }`}
          >
            <div className="rounded-50% w-40 h-40 bg-eggshell mr-16 flex-shrink-0 mb-12 sm:mb-0 flex items-center justify-center">
              <Icon
                attributes={{
                  className: iconClass[type],
                  color: 'plum',
                  name: icon,
                  style: 'filled',
                  type: 'functional',
                }}
              />
            </div>
            <div className="flex items-center">
              <p className="font-medium text-26 leading-32 mr-4">
                {headings[type]}
              </p>{' '}
              {questionMarkIcon('relative sm:hidden -top-8')}
            </div>
          </div>

          {[
            'Call',
            'CSAT',
            'Touches',
          ].includes(type) ?
            ''
            : (
              <div
                className="flex justify-between items-center max-w-275 sm:w-1/3 sm:mr-112"
                style={wrapperStyles}
              >
                <div
                  className="absolute bg-plum rounded-2 transform duration-100"
                  style={
                    isToggled
                      ? {
                        ...togglerStyles,
                        transform: 'translateX(100%)',
                      }
                      : togglerStyles
                  }
                />

                <div
                  className="px-16 py-8 cursor-pointer flex items-center justify-center w-50%"
                  onClick={() => setIsToggled(false)}
                >
                  <p
                    className="text-14 text-gray active:text-white active:font-medium leading-22"
                    style={
                      isToggled
                        ? { color: '#595555' }
                        : {
                          color: '#fff',
                          fontWeight: 500,
                        }
                    }
                  >
                    {strings.medianKey}
                  </p>
                </div>

                <div
                  className="px-16 py-8 cursor-pointer flex items-center justify-center w-50%"
                  onClick={() => setIsToggled(true)}
                >
                  <Paragraph
                    attributes={{
                      className:
                      'text-14 text-gray active:text-white active:font-medium leading-22',
                      data: {
                        style: isToggled
                          ? {
                            color: '#fff',
                            fontWeight: 500,
                          }
                          : { color: '#595555' },
                      },
                      innerContent: strings.ninetiethPercentileKey,
                    }}
                  />
                </div>
              </div>
            )}

          {questionMarkIcon('hidden sm:flex')}
        </div>

        <p
          className="text-28 leading-38 sm:text-48 sm:leading-58"
          style={{ fontWeight: 600 }}
        >
          {theAverage}
        </p>

        <div className="sm:flex items-center justify-between mb-32">
          <p
            className="font-medium text-16 leading-22 mb-8 sm:mb-0"
            style={{ color: '#595555' }}
          >
            {strings.ninetyDayAverageKey}
          </p>
        </div>
        {type === 'Touches' ? (
          <div
            className="flex items-start sm:items-center flex-col sm:flex-row sm:ml-auto mb-32"
            style={{ marginTop: '-16px' }}
          >
            <div className="flex items-center mr-28">
              <div
                className="w-12 h-12 mr-12 rounded-50%"
                style={{ backgroundColor: '#FDB71C' }}
              />
              <Paragraph
                attributes={{
                  className: 'text-16 leading-22',
                  innerContent: strings.firstResponseKey,
                }}
              />
            </div>

            <div className="flex items-center mr-28">
              <div
                className="w-12 h-12 mr-12 rounded-50%"
                style={{ backgroundColor: '#196E5F' }}
              />
              <Paragraph
                attributes={{
                  className: 'text-16 leading-22',
                  innerContent: strings.secondResponseKey,
                }}
              />
            </div>

            <div className="flex items-center mr-28">
              <div
                className="w-12 h-12 mr-12 rounded-50%"
                style={{ backgroundColor: '#502E3C' }}
              />
              <Paragraph
                attributes={{
                  className: 'text-16 leading-22',
                  innerContent: strings.thirdResponseKey,
                }}
              />
            </div>

            <div className="flex items-center mr-28">
              <div
                className="w-12 h-12 mr-12 rounded-50%"
                style={{ backgroundColor: '#C3402C' }}
              />
              <Paragraph
                attributes={{
                  className: 'text-16 leading-22',
                  innerContent: strings.fourthResponseKey,
                }}
              />
            </div>
          </div>
        ) :
          ''
        }

        <div className="recharts-support">
          <h5
            className="is-style-overline"
            style={{
              color: 'rgb(128, 128, 128)',
              left: 0,
              marginBottom: 0,
              position: 'absolute',
              textAlign: 'center',
              top: '50%',
              transform: `rotate(-90deg) translate(20%, ${translatePx})`,
              width: '100px',
            }}
          >
            {data.increment}
          </h5>

          <ResponsiveContainer height="100%" width="100%">
            <LineChart data={data.points}>
              <CartesianGrid horizontal={false} vertical={false} />
              <XAxis
                dataKey="name"
                interval={0}
                padding={{
                  left: 30,
                  right: 20,
                }}
                tick={<CustomizedXAxisTick />}
                tickLine={false}
              />
              <YAxis
                allowDecimals={false}
                tick={(
                  <CustomizedYAxisTick
                    isPercent={[
                      'Touches',
                      'Call',
                      'CSAT',
                    ].includes(type)}
                  />
                )}
                tickLine={false}
              />
              <Tooltip
                content={(
                  <CustomTooltip
                    isToggled={isToggled}
                    strings={strings}
                    type={type}
                  />
                )}
                cursor={false}
              />
              {lines}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

const Support = ({ main, strings }) => [
  <style
    dangerouslySetInnerHTML={{
      __html: `
      .recharts-support {
        height: 200px;
        width: 100%;
      }

      @media (max-width: 767px) {
        .recharts-support .recharts-xAxis g.recharts-layer.recharts-cartesian-axis-tick text:not(.-first) {
          display: none; 
        }
        .recharts-support .recharts-xAxis g.recharts-layer.recharts-cartesian-axis-tick:last-child text {
          display: block;
        }
      }

      .recharts-support .recharts-label-transform > div {
        bottom: -100px;
        transform: translate(-40%, 0%) rotate(-90deg);
      }

      @media (min-width: 768px) {
        .recharts-support {
          height: 270px;
          max-width: none;
        }

        .recharts-support .recharts-label-transform > div {
          bottom: auto;
          transform: translate(-40%, -100%) rotate(-90deg);
          top: 50%;
        }
      }
    `,
    }}
    key="1"
  />,

  <section key="2" className="bg-plum pt-112 sm:pt-144 pb-96">
    <div
      className="absolute bg-cover top-0 left-0 w-100% h-100%"
      style={{
        backgroundImage:
          'url(https://cdn.rippling.com/static/marketing/site-content/files/support-bg.svg)',
      }}
    />

    <div className="grid xsx:max-w-none smx:max-w-none">
      <div className="row">
        <div className="w-100% text-white text-center">
          <h1 className="mb-16">{strings.realTimeSupportStatusKey}</h1>
          <Paragraph
            attributes={{
              className: 'text-16 leading-22 max-w-650 mx-auto',
              data: {},
              innerContent: `
            ${strings.qualityOfSupportKey}
            <br />
            <br />
            ${strings.leadTheWayKey}
            `,
            }}
          />
        </div>
      </div>
    </div>
  </section>,

  <section key="3" className="bg-eggshell pt-88 pb-64">
    <div className="grid xsx:max-w-none smx:max-w-none mb-32">
      <div className="row mb-64">
        <h2>{strings.howFast}</h2>
      </div>

      <div className="row sm:items-center">
        <p className="font-medium text-24 leading-32 sm:text-28 sm:leading-36 mb-16 sm:mb-0">
          {strings.TFRKey}
        </p>

        <div className="flex">
          <div className="w-20 h-20 mr-10 flex items-center justify-center">
            <div className="w-20 h-20 flex items-center justify-center">
              <Icon
                attributes={{
                  color: 'tertiary-yellow',
                  name: 'RotatingArrows',
                  style: 'filled',
                  type: 'functional',
                }}
              />
            </div>
          </div>
          <p className="text-16 leading-22">{strings.dataRefreshedKey}</p>
        </div>
      </div>
    </div>

    <Graph
      data={main.Chat}
      strings={strings}
      type="Chat"
    />
    <Graph
      data={main.Call}
      strings={strings}
      type="Call"
    />
    <Graph
      data={main.Email}
      strings={strings}
      type="Email"
    />

    <div className="grid mb-32 mt-120 xsx:max-w-none">
      <div className="row mb-64">
        <h2>{strings.howEffective}</h2>
      </div>

      <div className="row sm:items-center">
        <p className="text-24 leading-32 sm:text-28 sm:leading-36 mb-16 sm:mb-0">
          {strings.FCRKey}
        </p>

        {/* <div className="flex">
          <Icon attributes={{name: 'RotatingArrows', type: 'functional', style: 'filled', color: 'tertiary-yellow', iconClass: 'w-20 h-20', className: 'w-20 h-20 mr-10' }} />
          <p className="text-16 leading-22">Data refreshed daily at 9am PT</p>
        </div> */}
      </div>
    </div>

    <Graph
      data={main.Touches}
      strings={strings}
      type="Touches"
    />

    <div className="grid mb-32 mt-64 xsx:max-w-none">
      <div className="row sm:items-center">
        <p className="text-24 leading-32 sm:text-28 sm:leading-36 mb-16 sm:mb-0">
          {strings.SupportStatusKey}
        </p>
      </div>
    </div>

    <Graph
      data={main.CSAT}
      strings={strings}
      type="CSAT"
    />
  </section>,
];

export default Support;
